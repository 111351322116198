.text-highlight {
  color : #F6A76E;
}

.text-line {
  max-width: 450px;
}
@media (min-width: 992) {
  .text-line {
    max-width: 500px;
  }
}
@media (min-width: 1200) {
  .text-line {
    max-width: 600px;
  }
}

.icon-circle {
  width: 50px;
  height: 50px;
  background: #5158a1;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.bullet-icon {
  margin-top: 5px;
  padding: 5px;
  width: 40px;
  height: 40px;
}