.video-carousel {
  background-color: rgb(68, 68, 68);
}

.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.arrows {
  color: lightgray;
  font-size: 3rem;
}

.arrows:hover {
  opacity: 0.8;
}

.scroll-menu-arrow--disabled {
  display: none;
}

.carousel-wrapper {
  max-width: 100%;
}

.border-1px {
  background-color: #FA822C;
  height: 1px;
}

.border-1px::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #FA822C;
}

.border-1px::after {
  content: "";
  top: -1px;
  position: relative;
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #07080F;
}