.reference-section-img {
  padding: 12px;
}

.icon {
  position:relative;
	text-align:center;
	width:0px;
	height:0px;
	padding:20px;
  border-radius: 50%;
}


.icon span {
	font-size:20px;
	position:absolute;
	left:9px;
	top:10px;
}

.icon.social {
	float:left;
	margin:0 5px 0 0;
	cursor:pointer;
	background:#6d6e71 ;
	color:#262626;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s; 	
}

.icon.social.facebook span {
	left:14px;
	top:10px;
}
.icon.social.twitter span {
	left:12px;
}
.icon.social.linkedin span {
	left:12px;
}
.icon.social.gmail span {
	left:10px;
}