.highlight-title {
  color: rgba(250, 130, 44, 1);
}

.highlight-infos {
  color: #6d6d6d;
  font-size: 1.1rem;
  font-family: 'Roboto';
}

#video-highlight {
  background-color: #171b32;
}

.hr-orange {
  height: 1px;
  background-image: -webkit-linear-gradient(left, rgba(250, 130, 44,.8), rgba(250, 130, 44,.6), rgba(0,0,0,0));
}