.section-image-bg {
  position: relative;
  width: 100%;
}

.overlay-right {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(0, 0, 0));
}

.overlay-left {
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(255, 255, 255, 0));
}

.section-headline {
  color : white;
  font-size: 5em;
}

.section-text {
  color: whitesmoke;
  font-size: 1.2em;
  width: 20vw;
}