.wip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #F96E0B;
}

.fixed-logo {
  position: absolute;
  z-index: 5;
  width: calc(4rem + 18vw);
  left: 2%;
  top: 1%;
}

.video-wrapper {
  position: relative;
  background-color: #07080F;
}

.video-overlay {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 50;
  color:#fff;
}

.social-icons {
  position: absolute;
  bottom: 4vh;
  text-align: center; 
  left: 0;
}

@keyframes rotator{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.social-icon {
  margin: 0px 1vw;
  text-align: center;
  color: whitesmoke;
}

.social-icon:hover {
  text-decoration: none;
  color: whitesmoke;
  animation: rotator .8s normal 0s linear;
}

.social-icon--facebook {
  background-color: #3b5998;
}

.social-icon--linkedin {
  background-color: #0077b5;
}

.social-icon--youtube {
  background-color: #b31217;
}

.social-icon--vimeo {
  background-color: #1ab7ae;
}

.landing-links {
  width: calc(1rem + 3vw);
  height: calc(1rem + 3vw);
  line-height: calc(0.9rem + 3.2vw);
  border-radius: 50%;
  font-size: calc(0.9rem + 1.8vw);
}

.responsive-center-div {
  text-align: center;
}

@media (min-width: 768px) {
  .responsive-center-div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.center-div{
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.site-socials {
  margin-top: 80px;
}

.landing-arrow {
  position: absolute;
  bottom: 0;
  left:0;
  text-align: center;
  width:100%;
}

.site-title, .site-headline {
  color: whitesmoke;
}

.site-title {
  font-weight: 500;
}

.h1-fluid {
  font-size: calc(1rem + 3vw);
  line-height: calc(1.4rem + 4.8vw);
}
  
.h2-fluid {
  font-size: calc(1rem + 2vw);
  line-height: calc(1.4rem + 2.4vw);
}
  
.h3-fluid {
  font-size: calc(1rem + 1vw);
  line-height: calc(1.4rem + 1.2vw);
}
  
.p-fluid {
  font-size: calc(1rem + 0.5vw);
  line-height: calc(1.4rem + 0.6vw);
}

.small-fluid {
  font-size: calc(0.9rem + 0.3vw);
  line-height: calc(1.3rem + 0.4vw);
}

.xs-socials-backup {
  text-align: center;
  position: absolute;
  right: 2%;
  /* top: 50%; */
  /* transform: translateY(50%); */
  /* TODO: changer vidéo + aligner socials */
}

.xs-socials-backup > a {
  display: block !important;
  margin-top: 15%;

}
/* 
.fixed-bottom {
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
} */