@import url('https://fonts.googleapis.com/css?family=Dosis');

body {
  font-family: 'Mosk' !important;
  background-color: #07080F;
}

.h1-fluid {
  font-size: calc(1rem + 3vw);
  line-height: calc(1.4rem + 4.8vw);
}
  
.h2-fluid {
  font-size: calc(1rem + 2vw);
  line-height: calc(1.4rem + 2.4vw);
}
  
.h3-fluid {
  font-size: calc(1rem + 1vw);
  line-height: calc(1.4rem + 1.2vw);
}
  
.p-fluid {
  font-size: calc(1rem + 0.5vw);
  line-height: calc(1.4rem + 0.6vw);
}

.small-fluid {
  font-size: calc(0.9rem + 0.3vw);
  line-height: calc(1.3rem + 0.4vw);
}