.img-reverse-triangle {
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}

.img-triangle {
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.right-inset-arrow {
  right: -15px;
  top: calc(50% - 15px);
  height: 30px;
  width: 30px;
  background-color: #353969;
  transform: rotate(45deg);
  z-index: 5;
  border: 2px solid #AFAFAF;
}

.left-inset-arrow {
  left: -15px;
  top: calc(50% - 15px);
  height: 30px;
  width: 30px;
  background-color: #353969;
  transform: rotate(45deg);
  z-index: 5;
  border: 2px solid #AFAFAF;
}