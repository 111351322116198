.common-team-card {
    /* background-color: #29758E; */
    opacity: 0.95;
    color: white;
  }
  
  .common-team-card:after {
  border-style: solid;
  border-width: 50px 0 50px 100px;
  border-color: transparent transparent transparent #007bff;
  }
