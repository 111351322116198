.video-thumbnail {
  transition: all .2s ease-in-out;
  width: 100%;
  cursor: pointer;
}

.video-thumbnail:hover {
  transform: scale(1.1);
}

.selected-video {
  position: relative;
  border: 3px solid whitesmoke;
}
