.mute-button {
  background-color: #07080F;
  opacity: 0.8;
  color: whitesmoke;
  border-radius: 50%;
  position: absolute;
  left : 5vw;
  bottom : 4vh;
  height: calc(20px + 2.5vw);
  width: calc(20px + 2.5vw);
  font-size: calc(10px + 2.4vw);
  line-height: calc(10px + 2.4vw);
  text-align: center;
  border: 2px whitesmoke solid;
  cursor: pointer;
}

.mute-button:focus {outline:0;}