.dot {
  height: 12vh;
  width: 12vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}

.pseudo-card {
  color: white;
  font-size: 1.1rem;
}

.text-orange {
  color: #fa822c;
  font-size: 1.2rem;
  font-weight: 500;
}

.address {
  color: white;
}

/* .presentation-section {
  position: relative;
  z-index: 900;
} */

#presentation-section {
  background-color: #131425;
  /* background-color: #07080F; */
}
/* 
.particlesjs {
  position : absolute;
  width:100%;
  height:100%;
  z-index:-1;
} */

.blue-text {
  color: #29758E;
}

.who{
  background: #353969;
  -webkit-clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
  clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
}

@media (min-width: 576px) { 
  .who{
    background: #353969;
  }
 }

 .stats-section{
  background: #353969;
  /* background: #AFAFAF; */

  /* border-bottom-style: solid;
  border-bottom-width: 0.18em;
  border-bottom-color:#F88302;
  border-bottom-color:#AFAFAF; */

  /* border-top-style: solid;
  border-top-width: 0.18em;
  border-top-color:#AFAFAF; */
}

.team-section{
  /* background: blue; */
}