.credit-link {
  color: #EA9E69;
}

.credit-link:hover {
  color: #EA9E69;
}

.arrow-border-wrapper {
  position: relative;
  width: 40%;
  border-bottom: 2px solid #FA822C;
}

.text-light {
  color: whitesmoke
}

.arrow-border {
  position: absolute;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: #091119;
  transform: translateY(-18px) rotate(45deg);
  border-bottom: 2px solid #FA822C;
  border-right: 2px solid #FA822C;
}

.ul-socials {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  left: 0;
}
/* xs */
@media (max-width: 575px) {
  .ul-socials {
    transform: scale(0.5);
    margin-left: -118px;
  }
}

/* sm */
@media (min-width: 576px) and (max-width: 768px) {
  .ul-socials {
    transform: scale(0.5);
    margin-left: -120px;
  }
}

/* md */
@media (min-width: 769px) and (max-width: 992px) {
  .ul-socials {
    transform: scale(0.7); 
    margin-left: -80px;
  }
}

/* lg */
@media (min-width: 993px) and (max-width: 1200px) {
  .ul-socials {
    transform: scale(0.8);
    margin-left: -40px;
  }
}

/* xl */
@media (min-width: 1201px) {
  .ul-socials {
    transform: scale(0.9);
    margin-left: 0px;
  }
}


.ul-socials li {
  margin: 0 40px;
}

.ul-socials li a .fa {
  font-size: 40px;
  color: #555;
  line-height: 80px;
  transition: 0.5s;
}

.ul-socials li a {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  text-align: center;
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
  transition: 0.5s;
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
}

.ul-socials li a::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  height: 100%;
  width: 20px;
  background: #b2b2b2;
  transition: 0.5s;
  transform: rotate(0deg) skewY(-45deg);
}

.ul-socials li a::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: -10px;
  height: 20px;
  width: 100%;
  background: #e5e5e5;
  transition: 0.5s;
  transform: rotate(0deg) skewX(-45deg);
}

.ul-socials li a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px,-20px);
  box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
}

.ul-socials li:hover .fa {
  color: #fff;
}

/* vimeo */
.ul-socials li:hover:nth-child(1) a {
  background-color: #1AB7EA;
}

.ul-socials li:hover:nth-child(1) a::before {
  background-color: #13a1cf;
}

.ul-socials li:hover:nth-child(1) a::after {
  background-color: #49c6ee;
}

/* youtube */
.ul-socials li:hover:nth-child(2) a {
  background-color: #E62117;
}

.ul-socials li:hover:nth-child(2) a::before {
  background-color: #b81a12;
}

.ul-socials li:hover:nth-child(2) a::after {
  background-color: #eb3d34;
}

/* linkedin */
.ul-socials li:hover:nth-child(3) a {
  background-color: #0077b5;
  /* background-color: #dd4b39; */
}

.ul-socials li:hover:nth-child(3) a::before {
  /* background-color: #b03c2d; */
  
  background-color: #005f90;
}

.ul-socials li:hover:nth-child(3) a::after {
  /* background-color: #e05d4c; */
  background-color: #1984bc;
}

/* facebook */
.ul-socials li:hover:nth-child(4) a {
  background-color: #3b5999;
}

.ul-socials li:hover:nth-child(4) a::before {
  /* background-color: #005f90; */
  background-color: #2f477a;
}

.ul-socials li:hover:nth-child(4) a::after {
  /* background-color: #1984bc; */
  background-color: #4e69a3;
}

.ul-socials li:hover:nth-child(5) a {
  background-color: #e4405f;
}

.ul-socials li:hover:nth-child(5) a::before {
  background-color: #b6334c;
}

.ul-socials li:hover:nth-child(5) a::after {
  background-color: #e6536f;
}

/* sm */
.footer-central-col-sm {
  background: inherit;
  /* border-bottom: 1px solid #FA822C; */
}

/* md */
@media (min-width: 576px) {
  .footer-central-col {
    border-left: 1px solid #FA822C;
  }
}

.footer-central-header {
  text-decoration: underline #353969;
}

.circle-icon {
  border: 2px solid whitesmoke;
  color: whitesmoke;
}

/* xs */
@media (max-width: 575px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.25em 0.42em;
    font-size: 1.7rem;
  }
}

/* sm */
@media (min-width: 576px) and (max-width: 768px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.30em 0.48em;
    font-size: 1.9rem;
  }
}

/* md */
@media (min-width: 769px) and (max-width: 992px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.38em 0.56em;
    font-size: 2.1rem;
  }
}

/* lg */
@media (min-width: 993px) and (max-width: 1200px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.45em 0.60em;
    font-size: 2.3rem;
  }
}

/* xl */
@media (min-width: 1201px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.45em 0.60em;
    font-size: 2.3rem;
  }
}