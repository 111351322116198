.marquee {
  overflow: hidden;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.child {
  background-color: #F9F9F9;
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.child > img {
  border-left: 1px solid #353969;
} 