@import url(https://fonts.googleapis.com/css?family=Dosis);
body {
  margin: 0;
  padding: 0;
}

.section {
  width: 100%;
  /* padding: 0 7%; */
  /* display: table; */
  margin: 0;
  max-width: none;
  /* background-color: #373B44; */
  height: 100vh;
}
body {
  font-family: 'Mosk' !important;
  background-color: #07080F;
}

.h1-fluid {
  font-size: calc(1rem + 3vw);
  line-height: calc(1.4rem + 4.8vw);
}
  
.h2-fluid {
  font-size: calc(1rem + 2vw);
  line-height: calc(1.4rem + 2.4vw);
}
  
.h3-fluid {
  font-size: calc(1rem + 1vw);
  line-height: calc(1.4rem + 1.2vw);
}
  
.p-fluid {
  font-size: calc(1rem + 0.5vw);
  line-height: calc(1.4rem + 0.6vw);
}

.small-fluid {
  font-size: calc(0.9rem + 0.3vw);
  line-height: calc(1.3rem + 0.4vw);
}


.phone-section{
    background: #353969;

    /* border-top-style: solid;
    border-top-width: 0.18em;
    border-top-color:#F88302;

    border-bottom-style: solid;
    border-bottom-width: 0.18em;
    border-bottom-color:#AFAFAF; */

}

.full-contact-card{
    /* background: red; */
}
.common-team-card {
    /* background-color: #29758E; */
    opacity: 0.95;
    color: white;
  }
  
  .common-team-card:after {
  border-style: solid;
  border-width: 50px 0 50px 100px;
  border-color: transparent transparent transparent #007bff;
  }

/* .content-section{
    background: red;
}

.map-content{
    background: green;
} */

.dot {
  height: 12vh;
  width: 12vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}

.pseudo-card {
  color: white;
  font-size: 1.1rem;
}

.text-orange {
  color: #fa822c;
  font-size: 1.2rem;
  font-weight: 500;
}

.address {
  color: white;
}

/* .presentation-section {
  position: relative;
  z-index: 900;
} */

#presentation-section {
  background-color: #131425;
  /* background-color: #07080F; */
}
/* 
.particlesjs {
  position : absolute;
  width:100%;
  height:100%;
  z-index:-1;
} */

.blue-text {
  color: #29758E;
}

.who{
  background: #353969;
  -webkit-clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
  clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
}

@media (min-width: 576px) { 
  .who{
    background: #353969;
  }
 }

 .stats-section{
  background: #353969;
  /* background: #AFAFAF; */

  /* border-bottom-style: solid;
  border-bottom-width: 0.18em;
  border-bottom-color:#F88302;
  border-bottom-color:#AFAFAF; */

  /* border-top-style: solid;
  border-top-width: 0.18em;
  border-top-color:#AFAFAF; */
}

.team-section{
  /* background: blue; */
}
.orange-outline {
  border-radius: 0px;
  border-width: 2px;
  border-color: #fa822c;
  color: #fa822c;
  text-transform: uppercase;
  padding: .7rem 1.4rem;
  font-weight: 500;
  -webkit-transition: .3s ease-in;
  transition: .3s ease-in;
}

.orange-outline:hover {
  color:  whitesmoke;
  background-color: #fa822c;
  border-color: #fa822c;
}
.video-carousel {
  background-color: rgb(68, 68, 68);
}

.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.arrows {
  color: lightgray;
  font-size: 3rem;
}

.arrows:hover {
  opacity: 0.8;
}

.scroll-menu-arrow--disabled {
  display: none;
}

.carousel-wrapper {
  max-width: 100%;
}

.border-1px {
  background-color: #FA822C;
  height: 1px;
}

.border-1px::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #FA822C;
}

.border-1px::after {
  content: "";
  top: -1px;
  position: relative;
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #07080F;
}
.video-thumbnail {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  width: 100%;
  cursor: pointer;
}

.video-thumbnail:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.selected-video {
  position: relative;
  border: 3px solid whitesmoke;
}

.highlight-title {
  color: rgba(250, 130, 44, 1);
}

.highlight-infos {
  color: #6d6d6d;
  font-size: 1.1rem;
  font-family: 'Roboto';
}

#video-highlight {
  background-color: #171b32;
}

.hr-orange {
  height: 1px;
  background-image: -webkit-linear-gradient(left, rgba(250, 130, 44,.8), rgba(250, 130, 44,.6), rgba(0,0,0,0));
}
.video-row {
  background-color: #07080F;
}
.z-index-custom {
}


.mute-button {
  background-color: #07080F;
  opacity: 0.8;
  color: whitesmoke;
  border-radius: 50%;
  position: absolute;
  left : 5vw;
  bottom : 4vh;
  height: calc(20px + 2.5vw);
  width: calc(20px + 2.5vw);
  font-size: calc(10px + 2.4vw);
  line-height: calc(10px + 2.4vw);
  text-align: center;
  border: 2px whitesmoke solid;
  cursor: pointer;
}

.mute-button:focus {outline:0;}

.wip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #F96E0B;
}

#landing-section {
  z-index: 10000 !important;
}

#landing-section.fullscreen {
  height: 100vh;
  width: 100vw;
  background-color: #07080F;
  display: flex;
  position: fixed;
  z-index: 10;
}
 
#landing-section.fullscreen > .video-wrapper {
  align-self: center;
  width: 100%;
}

/* .fixed-loo {
  position: absolute;
  z-index: 5;
  width: calc(5rem + 25vw);
  left: 3%;
  top: 2%;
} */

.video-wrapper {
  position: relative;
  background-color: #07080F;
}

.video-overlay {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 50;
  color:#fff;
}

.social-icons {
  position: absolute;
  bottom: 4vh;
  text-align: center; 
  left: 0;
}

@-webkit-keyframes rotator{
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotator{
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.social-icon {
  margin: 0px 1vw;
  text-align: center;
  color: whitesmoke;
}

.social-icon:hover {
  text-decoration: none;
  color: whitesmoke;
  -webkit-animation: rotator .8s normal 0s linear;
          animation: rotator .8s normal 0s linear;
}

.social-icon--facebook {
  background-color: #3b5998;
}

.social-icon--linkedin {
  background-color: #0077b5;
}

.social-icon--youtube {
  background-color: #b31217;
}

.social-icon--vimeo {
  background-color: #1ab7ae;
}

.landing-links {
  width: calc(1rem + 3vw);
  height: calc(1rem + 3vw);
  line-height: calc(0.9rem + 3.2vw);
  border-radius: 50%;
  font-size: calc(0.9rem + 1.8vw);
}

.responsive-center-div {
  text-align: center;
}

@media (min-width: 768px) {
  .responsive-center-div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.center-div{
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.site-socials {
  margin-top: 80px;
}

.landing-arrow {
  position: absolute;
  bottom: 0;
  left:0;
  text-align: center;
  width:100%;
}

.site-title, .site-headline {
  color: whitesmoke;
}

.site-title {
  font-weight: 500;
}

.xs-socials-backup {
  text-align: center;
  position: absolute;
  right: 2%;
  /* top: 50%; */
  /* transform: translateY(50%); */
  /* TODO: changer vidéo + aligner socials */
}

.xs-socials-backup > a {
  display: block !important;
  margin-top: 15%;

}
/* 
.fixed-bottom {
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
} */
.wip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #F96E0B;
}

.fixed-logo {
  position: absolute;
  z-index: 5;
  width: calc(4rem + 18vw);
  left: 2%;
  top: 1%;
}

.video-wrapper {
  position: relative;
  background-color: #07080F;
}

.video-overlay {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 50;
  color:#fff;
}

.social-icons {
  position: absolute;
  bottom: 4vh;
  text-align: center; 
  left: 0;
}

@-webkit-keyframes rotator{
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotator{
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.social-icon {
  margin: 0px 1vw;
  text-align: center;
  color: whitesmoke;
}

.social-icon:hover {
  text-decoration: none;
  color: whitesmoke;
  -webkit-animation: rotator .8s normal 0s linear;
          animation: rotator .8s normal 0s linear;
}

.social-icon--facebook {
  background-color: #3b5998;
}

.social-icon--linkedin {
  background-color: #0077b5;
}

.social-icon--youtube {
  background-color: #b31217;
}

.social-icon--vimeo {
  background-color: #1ab7ae;
}

.landing-links {
  width: calc(1rem + 3vw);
  height: calc(1rem + 3vw);
  line-height: calc(0.9rem + 3.2vw);
  border-radius: 50%;
  font-size: calc(0.9rem + 1.8vw);
}

.responsive-center-div {
  text-align: center;
}

@media (min-width: 768px) {
  .responsive-center-div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.center-div{
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.site-socials {
  margin-top: 80px;
}

.landing-arrow {
  position: absolute;
  bottom: 0;
  left:0;
  text-align: center;
  width:100%;
}

.site-title, .site-headline {
  color: whitesmoke;
}

.site-title {
  font-weight: 500;
}

.h1-fluid {
  font-size: calc(1rem + 3vw);
  line-height: calc(1.4rem + 4.8vw);
}
  
.h2-fluid {
  font-size: calc(1rem + 2vw);
  line-height: calc(1.4rem + 2.4vw);
}
  
.h3-fluid {
  font-size: calc(1rem + 1vw);
  line-height: calc(1.4rem + 1.2vw);
}
  
.p-fluid {
  font-size: calc(1rem + 0.5vw);
  line-height: calc(1.4rem + 0.6vw);
}

.small-fluid {
  font-size: calc(0.9rem + 0.3vw);
  line-height: calc(1.3rem + 0.4vw);
}

.xs-socials-backup {
  text-align: center;
  position: absolute;
  right: 2%;
  /* top: 50%; */
  /* transform: translateY(50%); */
  /* TODO: changer vidéo + aligner socials */
}

.xs-socials-backup > a {
  display: block !important;
  margin-top: 15%;

}
/* 
.fixed-bottom {
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
} */

.img-col {
  background-color: #353969;
  color: "white";
}

@media (min-width: 768px) {
  .img-col {
    min-height: "40vh";
  }
}
.img-reverse-triangle {
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}

.img-triangle {
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.section-title {
  font-size: calc(.9rem + 3vh);
  text-transform: uppercase;
  color: whitesmoke;
  font-weight: 900;
}

.text-line {
  color: whitesmoke;
}

.section-content {
  color: whitesmoke;
  font-size: calc(1rem + 0.5vw);
  line-height: calc(1.4rem + 0.6vw);
  font-weight: 500;
}
.text-highlight {
  color : #F6A76E;
}

.text-line {
  max-width: 450px;
}
@media (min-width: 992) {
  .text-line {
    max-width: 500px;
  }
}
@media (min-width: 1200) {
  .text-line {
    max-width: 600px;
  }
}

.icon-circle {
  width: 50px;
  height: 50px;
  background: #5158a1;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.bullet-icon {
  margin-top: 5px;
  padding: 5px;
  width: 40px;
  height: 40px;
}



.img-reverse-triangle {
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}

.img-triangle {
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.right-inset-arrow {
  right: -15px;
  top: calc(50% - 15px);
  height: 30px;
  width: 30px;
  background-color: #353969;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 5;
  border: 2px solid #AFAFAF;
}

.left-inset-arrow {
  left: -15px;
  top: calc(50% - 15px);
  height: 30px;
  width: 30px;
  background-color: #353969;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 5;
  border: 2px solid #AFAFAF;
}


.marquee {
  overflow: hidden;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.child {
  background-color: #F9F9F9;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.child > img {
  border-left: 1px solid #353969;
} 
.reference-section-img {
  padding: 12px;
}

.icon {
  position:relative;
	text-align:center;
	width:0px;
	height:0px;
	padding:20px;
  border-radius: 50%;
}


.icon span {
	font-size:20px;
	position:absolute;
	left:9px;
	top:10px;
}

.icon.social {
	float:left;
	margin:0 5px 0 0;
	cursor:pointer;
	background:#6d6e71 ;
	color:#262626;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s; 	
}

.icon.social.facebook span {
	left:14px;
	top:10px;
}
.icon.social.twitter span {
	left:12px;
}
.icon.social.linkedin span {
	left:12px;
}
.icon.social.gmail span {
	left:10px;
}
.credit-link {
  color: #EA9E69;
}

.credit-link:hover {
  color: #EA9E69;
}

.arrow-border-wrapper {
  position: relative;
  width: 40%;
  border-bottom: 2px solid #FA822C;
}

.text-light {
  color: whitesmoke
}

.arrow-border {
  position: absolute;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: #091119;
  -webkit-transform: translateY(-18px) rotate(45deg);
          transform: translateY(-18px) rotate(45deg);
  border-bottom: 2px solid #FA822C;
  border-right: 2px solid #FA822C;
}

.ul-socials {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  left: 0;
}
/* xs */
@media (max-width: 575px) {
  .ul-socials {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    margin-left: -118px;
  }
}

/* sm */
@media (min-width: 576px) and (max-width: 768px) {
  .ul-socials {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    margin-left: -120px;
  }
}

/* md */
@media (min-width: 769px) and (max-width: 992px) {
  .ul-socials {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); 
    margin-left: -80px;
  }
}

/* lg */
@media (min-width: 993px) and (max-width: 1200px) {
  .ul-socials {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-left: -40px;
  }
}

/* xl */
@media (min-width: 1201px) {
  .ul-socials {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    margin-left: 0px;
  }
}


.ul-socials li {
  margin: 0 40px;
}

.ul-socials li a .fa {
  font-size: 40px;
  color: #555;
  line-height: 80px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.ul-socials li a {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  text-align: center;
  -webkit-transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
          transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
}

.ul-socials li a::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  height: 100%;
  width: 20px;
  background: #b2b2b2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: rotate(0deg) skewY(-45deg);
          transform: rotate(0deg) skewY(-45deg);
}

.ul-socials li a::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: -10px;
  height: 20px;
  width: 100%;
  background: #e5e5e5;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: rotate(0deg) skewX(-45deg);
          transform: rotate(0deg) skewX(-45deg);
}

.ul-socials li a:hover {
  -webkit-transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px,-20px);
          transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px,-20px);
  box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
}

.ul-socials li:hover .fa {
  color: #fff;
}

/* vimeo */
.ul-socials li:hover:nth-child(1) a {
  background-color: #1AB7EA;
}

.ul-socials li:hover:nth-child(1) a::before {
  background-color: #13a1cf;
}

.ul-socials li:hover:nth-child(1) a::after {
  background-color: #49c6ee;
}

/* youtube */
.ul-socials li:hover:nth-child(2) a {
  background-color: #E62117;
}

.ul-socials li:hover:nth-child(2) a::before {
  background-color: #b81a12;
}

.ul-socials li:hover:nth-child(2) a::after {
  background-color: #eb3d34;
}

/* linkedin */
.ul-socials li:hover:nth-child(3) a {
  background-color: #0077b5;
  /* background-color: #dd4b39; */
}

.ul-socials li:hover:nth-child(3) a::before {
  /* background-color: #b03c2d; */
  
  background-color: #005f90;
}

.ul-socials li:hover:nth-child(3) a::after {
  /* background-color: #e05d4c; */
  background-color: #1984bc;
}

/* facebook */
.ul-socials li:hover:nth-child(4) a {
  background-color: #3b5999;
}

.ul-socials li:hover:nth-child(4) a::before {
  /* background-color: #005f90; */
  background-color: #2f477a;
}

.ul-socials li:hover:nth-child(4) a::after {
  /* background-color: #1984bc; */
  background-color: #4e69a3;
}

.ul-socials li:hover:nth-child(5) a {
  background-color: #e4405f;
}

.ul-socials li:hover:nth-child(5) a::before {
  background-color: #b6334c;
}

.ul-socials li:hover:nth-child(5) a::after {
  background-color: #e6536f;
}

/* sm */
.footer-central-col-sm {
  background: inherit;
  /* border-bottom: 1px solid #FA822C; */
}

/* md */
@media (min-width: 576px) {
  .footer-central-col {
    border-left: 1px solid #FA822C;
  }
}

.footer-central-header {
  -webkit-text-decoration: underline #353969;
          text-decoration: underline #353969;
}

.circle-icon {
  border: 2px solid whitesmoke;
  color: whitesmoke;
}

/* xs */
@media (max-width: 575px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.25em 0.42em;
    font-size: 1.7rem;
  }
}

/* sm */
@media (min-width: 576px) and (max-width: 768px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.30em 0.48em;
    font-size: 1.9rem;
  }
}

/* md */
@media (min-width: 769px) and (max-width: 992px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.38em 0.56em;
    font-size: 2.1rem;
  }
}

/* lg */
@media (min-width: 993px) and (max-width: 1200px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.45em 0.60em;
    font-size: 2.3rem;
  }
}

/* xl */
@media (min-width: 1201px) {
  .circle-icon {
    border-radius: 50%;
    padding: 0.45em 0.60em;
    font-size: 2.3rem;
  }
}
.homepage {
  overflow-x: hidden;
}
.section-image-bg {
  position: relative;
  width: 100%;
}

.overlay-right {
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgb(0, 0, 0));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(0, 0, 0));
}

.overlay-left {
  background-image: -webkit-linear-gradient(left, rgb(0, 0, 0), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(255, 255, 255, 0));
}

.section-headline {
  color : white;
  font-size: 5em;
}

.section-text {
  color: whitesmoke;
  font-size: 1.2em;
  width: 20vw;
}
.cat-title {
  color: whitesmoke;
}
