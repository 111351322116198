.section-title {
  font-size: calc(.9rem + 3vh);
  text-transform: uppercase;
  color: whitesmoke;
  font-weight: 900;
}

.text-line {
  color: whitesmoke;
}

.section-content {
  color: whitesmoke;
  font-size: calc(1rem + 0.5vw);
  line-height: calc(1.4rem + 0.6vw);
  font-weight: 500;
}