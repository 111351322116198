.phone-section{
    background: #353969;

    /* border-top-style: solid;
    border-top-width: 0.18em;
    border-top-color:#F88302;

    border-bottom-style: solid;
    border-bottom-width: 0.18em;
    border-bottom-color:#AFAFAF; */

}

.full-contact-card{
    /* background: red; */
}