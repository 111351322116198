.orange-outline {
  border-radius: 0px;
  border-width: 2px;
  border-color: #fa822c;
  color: #fa822c;
  text-transform: uppercase;
  padding: .7rem 1.4rem;
  font-weight: 500;
  transition: .3s ease-in;
}

.orange-outline:hover {
  color:  whitesmoke;
  background-color: #fa822c;
  border-color: #fa822c;
}